exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-chinh-sach-tsx": () => import("./../../../src/pages/chinh-sach.tsx" /* webpackChunkName: "component---src-pages-chinh-sach-tsx" */),
  "component---src-pages-cong-dong-hoc-vien-tsx": () => import("./../../../src/pages/cong-dong-hoc-vien.tsx" /* webpackChunkName: "component---src-pages-cong-dong-hoc-vien-tsx" */),
  "component---src-pages-cross-platform-mobile-app-development-tsx": () => import("./../../../src/pages/cross-platform-mobile-app-development.tsx" /* webpackChunkName: "component---src-pages-cross-platform-mobile-app-development-tsx" */),
  "component---src-pages-dang-ky-thanh-cong-tsx": () => import("./../../../src/pages/dang-ky-thanh-cong.tsx" /* webpackChunkName: "component---src-pages-dang-ky-thanh-cong-tsx" */),
  "component---src-pages-dang-ky-tsx": () => import("./../../../src/pages/dang-ky.tsx" /* webpackChunkName: "component---src-pages-dang-ky-tsx" */),
  "component---src-pages-du-an-cua-hoc-vien-tsx": () => import("./../../../src/pages/du-an-cua-hoc-vien.tsx" /* webpackChunkName: "component---src-pages-du-an-cua-hoc-vien-tsx" */),
  "component---src-pages-fullstack-java-web-developer-tsx": () => import("./../../../src/pages/fullstack-java-web-developer.tsx" /* webpackChunkName: "component---src-pages-fullstack-java-web-developer-tsx" */),
  "component---src-pages-fullstack-javascript-web-developer-tsx": () => import("./../../../src/pages/fullstack-javascript-web-developer.tsx" /* webpackChunkName: "component---src-pages-fullstack-javascript-web-developer-tsx" */),
  "component---src-pages-fullstack-php-web-developer-tsx": () => import("./../../../src/pages/fullstack-php-web-developer.tsx" /* webpackChunkName: "component---src-pages-fullstack-php-web-developer-tsx" */),
  "component---src-pages-fullstack-python-web-developer-tsx": () => import("./../../../src/pages/fullstack-python-web-developer.tsx" /* webpackChunkName: "component---src-pages-fullstack-python-web-developer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-online-tsx": () => import("./../../../src/pages/lab-online.tsx" /* webpackChunkName: "component---src-pages-lab-online-tsx" */),
  "component---src-pages-mo-hinh-chat-luong-3-a-tsx": () => import("./../../../src/pages/mo-hinh-chat-luong-3a.tsx" /* webpackChunkName: "component---src-pages-mo-hinh-chat-luong-3-a-tsx" */),
  "component---src-pages-phuong-phap-dao-tao-tsx": () => import("./../../../src/pages/phuong-phap-dao-tao.tsx" /* webpackChunkName: "component---src-pages-phuong-phap-dao-tao-tsx" */),
  "component---src-pages-phuong-phap-su-pham-fmge-tsx": () => import("./../../../src/pages/phuong-phap-su-pham-fmge.tsx" /* webpackChunkName: "component---src-pages-phuong-phap-su-pham-fmge-tsx" */),
  "component---src-pages-quy-trinh-nhap-hoc-tsx": () => import("./../../../src/pages/quy-trinh-nhap-hoc.tsx" /* webpackChunkName: "component---src-pages-quy-trinh-nhap-hoc-tsx" */),
  "component---src-pages-thuat-ngu-chuyen-nganh-tsx": () => import("./../../../src/pages/thuat-ngu-chuyen-nganh.tsx" /* webpackChunkName: "component---src-pages-thuat-ngu-chuyen-nganh-tsx" */),
  "component---src-pages-ve-chung-toi-tsx": () => import("./../../../src/pages/ve-chung-toi.tsx" /* webpackChunkName: "component---src-pages-ve-chung-toi-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-student-story-template-tsx": () => import("./../../../src/templates/student-story-template.tsx" /* webpackChunkName: "component---src-templates-student-story-template-tsx" */)
}

